import React, { useState, useEffect, useCallback } from 'react';
import { Table, Switch, Button, Input, Space, message, Divider, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from './Config';

const { Title, Text } = Typography;

const CurrencyManager = () => {
  const [currencies, setCurrencies] = useState([]);
  const [filteredCurrencies, setFilteredCurrencies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const fetchCurrencies = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${config.apiUrl}/currencies`);
      setCurrencies(response.data);
      setFilteredCurrencies(response.data);
    } catch (error) {
      console.error('Error fetching currencies:', error);
      message.error('Failed to load currencies.');
    } finally {
      setLoading(false);
    }
  }, []);

  const updateCurrencyStatus = useCallback(
    async (currency) => {
      const updatedCurrency = { ...currency, isEnabled: !currency.isEnabled };

      try {
        await axios.put(`${config.apiUrl}/currencies/${currency.currencyId}`, updatedCurrency);
        message.success(`Currency ${updatedCurrency.isEnabled ? 'enabled' : 'disabled'} successfully.`);
        setCurrencies((prevCurrencies) =>
          prevCurrencies.map((curr) =>
            curr.currencyId === currency.currencyId ? updatedCurrency : curr
          )
        );
        setFilteredCurrencies((prevFilteredCurrencies) =>
          prevFilteredCurrencies.map((curr) =>
            curr.currencyId === currency.currencyId ? updatedCurrency : curr
          )
        );
      } catch (error) {
        console.error('Error updating currency status:', error);
        message.error('Failed to update currency status.');
      }
    },
    []
  );

  const handleSearch = useCallback(
    (value) => {
      setSearchTerm(value);
      const filtered = currencies.filter(
        (currency) =>
          currency.currencyCode.toLowerCase().includes(value.toLowerCase()) ||
          currency.currencyName.toLowerCase().includes(value.toLowerCase()) ||
          currency.symbol.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredCurrencies(filtered);
      setPage(1);
    },
    [currencies]
  );

  useEffect(() => {
    fetchCurrencies();
  }, [fetchCurrencies]);

  const columns = [
    {
      title: 'Code',
      dataIndex: 'currencyCode',
      key: 'currencyCode',
      responsive: ['md'],
    },
    {
      title: 'Name',
      dataIndex: 'currencyName',
      key: 'currencyName',
    },
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      responsive: ['md'],
    },
    {
      title: 'Enabled',
      dataIndex: 'isEnabled',
      key: 'isEnabled',
      render: (isEnabled, record) => (
        <Switch
          checked={isEnabled}
          onChange={() => updateCurrencyStatus(record)}
        />
      ),
    },
    {
      title: 'Last Modified',
      dataIndex: 'lastModified',
      key: 'lastModified',
      responsive: ['md'],
      render: (text) => new Date(text).toLocaleString(),
    },
  ];

  return (
    <div>
      <Title level={2}>Currency Management</Title>
      <Text>
        Use this page to enable or disable currencies in your Organization. These currencies will be available for Costs, Prices, etc.
      </Text>
      <Divider />
      <Space style={{ marginBottom: 16 }}>
        <Input.Search
          placeholder="Search currencies"
          onSearch={handleSearch}
          value={searchTerm}
          onChange={(e) => handleSearch(e.target.value)}
          style={{ maxWidth: 350 }}
        />
      </Space>
      <Table
        dataSource={filteredCurrencies.slice((page - 1) * pageSize, page * pageSize)}
        columns={columns}
        rowKey="currencyId"
        loading={loading}
        pagination={{
          current: page,
          pageSize: pageSize,
          total: filteredCurrencies.length,
          onChange: (newPage, newPageSize) => {
            setPage(newPage);
            setPageSize(newPageSize);
          },
        }}
      />
    </div>
  );
};

export default CurrencyManager;
