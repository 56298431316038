import React, { useEffect, useState } from 'react';
import { Drawer, Form, Button, Input, message, Checkbox } from 'antd';
import axios from 'axios';
import config from './Config';

const ExpenseTypeForm = ({ visible, onClose, expenseType, onSave }) => {
    const [form] = Form.useForm();
    const [isDistanceBased, setIsDistanceBased] = useState(false);

    useEffect(() => {
        if (expenseType) {
            form.setFieldsValue(expenseType);
            setIsDistanceBased(expenseType.isDistanceBased);
        } else {
            form.resetFields();
            setIsDistanceBased(false);
        }
    }, [expenseType, form]);

    const handleCheckboxChange = (e) => {
        setIsDistanceBased(e.target.checked);
    };

    const handleFinish = async (values) => {
        try {
            const payload = { 
                ...values, 
                IsDistanceBased: isDistanceBased, 
                ExpenseTypeId: expenseType ? expenseType.expenseTypeId : 0, // Agregar ExpenseTypeId para actualizar
                LastModified: new Date().toISOString() // Agregar LastModified a la fecha actual
            };

            if (expenseType) {
                // Llamada para actualizar el tipo de gasto
                await axios.put(`${config.apiUrl}/expenseType/${expenseType.expenseTypeId}`, payload);
                message.success('Expense type updated successfully');
            } else {
                // Llamada para crear un nuevo tipo de gasto
                await axios.post(`${config.apiUrl}/expenseType`, payload);
                message.success('Expense type created successfully');
            }

            onSave(); 
            onClose(); 
        } catch (error) {
            console.error('There was an error saving the expense type!', error);
            message.error('Failed to save expense type');
        }
    };

    return (
        <Drawer
            title={expenseType ? 'Edit Expense Type' : 'Create Expense Type'}
            width={720}
            onClose={onClose}
            open={visible}
        >
            <Form form={form} layout="vertical" onFinish={handleFinish}>
                <Form.Item
                    name="expenseTypeName"
                    label="Expense Type Name"
                    rules={[{ required: true, message: 'Please enter the expense type name' }]}
                >
                    <Input placeholder="Please enter the expense type name" />
                </Form.Item>
                <Form.Item
                    name="description"
                    label="Description"
                    rules={[{ required: true, message: 'Please enter the description' }]}
                >
                    <Input placeholder="Please enter the description" />
                </Form.Item>

                <Form.Item>
                    <Checkbox
                        checked={isDistanceBased}
                        onChange={handleCheckboxChange}
                    >
                        Based on Distance
                    </Checkbox>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {expenseType ? 'Update' : 'Create'}
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default ExpenseTypeForm;