// src/TaxRateForm.js
import React, { useEffect } from 'react';
import { Drawer, Form, Button, Input, message } from 'antd';
import axios from 'axios';
import config from './Config';

const TaxRateForm = ({ visible, onClose, taxRate, onSave }) => {
  const [form] = Form.useForm(); 

  useEffect(() => {
    if (taxRate) {
      
      form.setFieldsValue(taxRate); 
    } else {
      form.resetFields(); 
    }
  }, [taxRate, form]);

  const handleFinish = async (values) => {
    try {
      if (taxRate) {
        
        const updatedValues = { ...values, taxRateId: taxRate.taxRateId }; 
        await axios.put(`${config.apiUrl}/taxrate/${taxRate.taxRateId}`, updatedValues);
        message.success('Tax rate updated successfully');
      } else {
        
        await axios.post(`${config.apiUrl}/taxrate`, {
          rate: values.rate, 
          description: values.description, 
        });
        message.success('Tax rate created successfully');
      }
      onSave(); 
      onClose();
    } catch (error) {
      console.error('There was an error saving the tax rate!', error);
      message.error('Failed to save tax rate');
    }
  };

  return (
    <Drawer
      title={taxRate ? 'Edit Tax Rate' : 'Create Tax Rate'}
      width={720}
      onClose={onClose}
      open={visible}
    >
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Form.Item
          name="description" 
          label="Tax Rate Description"
          rules={[{ required: true, message: 'Please enter the tax rate description' }]}
        >
          <Input placeholder="Please enter the tax rate description" />
        </Form.Item>
        <Form.Item
          name="rate" 
          label="Tax Rate Value"
          rules={[{ required: true, message: 'Please enter the tax rate value' }]}
        >
          <Input placeholder="Please enter the tax rate value" type="number" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {taxRate ? 'Update' : 'Create'} 
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default TaxRateForm;